
import { defineComponent, ref } from 'vue';
import { useResetPasswordMutation } from '@/generated/graphql';
import { useRoute, useRouter } from 'vue-router';
import * as yup from 'yup';

import { Input, Button, Emoji } from '@bridge-immo/ui';
import { SchemaFormWithValidation } from '@/components/SchemaFormWithValidation';
import { onLogin, redirectAfterLogin } from '@bridge-immo/common';

export default defineComponent({
  components: {
    Emoji,
    SchemaFormWithValidation,
    Button
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const resetPasswordFormSchema = {
      password: {
        component: Input,
        type: 'password',
        name: 'password',
        label: 'Mot de passe',
        placeholder: '••••••••••••••••',
        validations: yup
          .string()
          .required()
          .min(6),
        autocomplete: 'current-password',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
               </svg>`
      }
    };

    const resetPasswordFormData = ref({
      password: '',
      token: route.params.token
    });
    const { mutate: resetPassword, onDone, loading } = useResetPasswordMutation({});
    onDone(async result => {
      if (result?.data?.resetPassword.accessToken && result?.data?.resetPassword.user) {
        try {
          await onLogin(result?.data?.resetPassword.accessToken);
          redirectAfterLogin(result.data.resetPassword.user, route);
        } catch (e) {
          console.log(e);
        }
      }
      // TODO VL
      console.log(result);
    });

    const goToLogin = () => {
      router.push({ name: 'login' });
    };

    return {
      resetPasswordFormData,
      resetPasswordFormSchema,
      resetPassword,
      loading,
      goToLogin
    };
  }
});
